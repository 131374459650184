.Banner {
  /* background: #0054a4; /* fallback for old browsers */
  background: #ffffff;
}

.Carousel {
  padding-top: 0px;
}

.ui.horizontal.list > .item {
  margin-left: 5px;
}
.whiteFont {
  color: white;
}

.cursor-like {
  cursor: pointer;
}
.redButton {
  /* background-color: #db2828 !important; */
  background-color: #db2828 !important;
  background: linear-gradient(
    rgba(255, 255, 255, 0.2) 0,
    rgba(255, 255, 255, 0.2) 100%
  ) !important;
  color: white !important;
}
.react-datepicker__navigation,
.react-datepicker__current-month {
  display: none;
}

.react-datepicker__month-dropdown-container,
.react-datepicker__year-dropdown-container {
  font-size: 14px;
  font-weight: bold;
}

.floatDiv-enter {
  opacity: 0.01;
}

.floatDiv-enter.floatDiv-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-in;
}

.floatDiv-leave {
  opacity: 1;
}

.floatDiv-leave.floatDiv-leave-active {
  opacity: 0.01;
  transition: opacity 400ms ease-in;
}

.floatButton {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 65px;
  right: 40px;
  background-color: #0054a4;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  text-decoration: #fff;
  box-shadow: 2px 2px 3px #999;
}
.floatButton:hover {
  color: #fff;
  text-decoration: none;
}
.floatButton:focus {
  outline: 0;
}

.floatButtonIcon {
  margin: 13px 0 0 0 !important;
  font-size: 32px !important;
}

.floatFormTitle {
  position: fixed;
  width: 250px;
  height: 55px;
  bottom: 425px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px 4px 0 0;
  padding: 5px 10px 5px 10px;
  right: 40px;
  background-color: #0054a4;
  color: #fff;
  box-shadow: 2px 2px 3px #999;
}

.floatForm {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 250px;
  height: 305px;
  bottom: 135px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0 0 4px 4px;
  padding: 5px 10px 5px 10px;
  right: 40px;
  color: #000;
  background-color: #fff;
  box-shadow: 2px 2px 3px #999;
}

.floatFormInput {
  width: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  box-shadow: none;
  outline: 0;
}

.floatFormTextArea {
  width: 100%;
  height: 100px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  box-shadow: none;
  outline: 0;
  resize: none;
}

.responseMessage {
  padding: 5px 10px 5px 10px;

  color: #000;
  background-color: #fff;

  font-size: 16px;
  font-weight: bolder;
  text-align: center;
}

.floatFormSubmitButton {
  border-radius: 4px;
  background-color: #0054a4;
  color: #fff;
  padding: 5px;
  width: 100%;
}
#whiteLogo {
  display: none;
}

.ui.blue.button,
.ui.blue.buttons .button {
  background-color: #0555a2 !important;
  color: #fff;
  text-shadow: none;
  background-image: none;
}

.ui.olive.button,
.ui.olive.buttons .button {
  background-color: #00acc9 !important;
  color: #fff;
  text-shadow: none;
  background-image: none;
}

i.olive.icon {
  color: #00acc9 !important;
}

.sampleSegment {
  background-color: #00acc9af !important;
  color: #fff;
  text-shadow: none;
  background-image: none;
}

.logginSeparation {
  margin-top: 50px;
}

.extraClass {
  width: 180px !important;
  font-size: 16px !important;
  text-align: left;
}

.tealText {
  color: #00acc9 !important;
}

.tealBackground {
  background: #00acc9 !important;
  color: #fff !important;
}
.title:hover {
  color: #00acc9 !important;
}

.active.title {
  color: #00acc9 !important;
}

.bacteriaColumn {
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
}
.bacteriaDiv {
  text-align: left;
  font-size: 2rem;
  border: 6px solid #00acc9;
  padding: 15px;
  border-radius: 10px;
}

#gotUser {
  margin-bottom: 0px;
}

#register {
  font-size: 57px;
}

.bacteria {
  display: block;
  font-weight: bolder;
  margin-left: auto;
  margin-right: auto;
  color: #00acc9;
  position: absolute;
  top: 130px;
}

.bacteria:hover {
  color: #00acc9;
}

.bacteria:hover {
  color: #00acc9;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.bacteriaDiv > a {
  color: #00acc9;
  /*font-size:3.5rem*/
}

.loginButtonFormField {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form_group_recaptcha > div > div {
  display: flex;
  place-content: center;
}

@media (max-width: 768px) {
  .logginSeparation {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .mobileLogo {
    margin-top: 20px;
  }
  .bacteriaDiv {
    position: static;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }
  .bacteria {
    position: unset;
    margin: 25px auto auto auto;
  }
}

@media print {
  body {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: black;
  }

  table {
    page-break-inside: avoid;
  }

  #whiteLogo {
    width: 150px;
    padding-top: 20px;
    display: block;
    position: relative;
  }

  #leftColumn,
  #generatePDF,
  #chartList,
  #svgImg {
    display: none;
  }
  a:after {
    content: " <" attr(href) ">";
  }

  #root {
    flex: 1 0 auto;
    /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their
content's default minimum size. */
  }
  #footer {
    flex-shrink: 0;
    /* Prevent Chrome, Opera, and Safari from letting
these items shrink to smaller than their content's default minimum size. */
    padding: 20px;
    align-self: center;
    position: fixed;
    bottom: 0px;
  }

  #segment {
    text-align: center;
    font-size: 16px;
    color: black;
    position: relative;
    /*background: rgb(224, 92, 92);*/
    margin: 1rem 0px;
    padding: 1em;
    border-radius: 0.285714rem;
    box-shadow: rgba(34, 36, 38, 0.15) 0px 1px 2px 0px;
  }
}
